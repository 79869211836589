/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
// import 'normalize.css';
import "typeface-inter"
import "./src/styles/global.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-dropdown/style.css"
import "lazysizes"

export const onRouteUpdate = () => {
  const body = document.querySelector("body")
  body.classList.add("fade-in")

  setTimeout(() => {
    body.classList.remove("fade-in")
  }, 500)
}
